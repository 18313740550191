import { useIntl, Link } from 'gatsby-plugin-react-intl';
import tw, { styled } from 'twin.macro';

import { sendEvent } from '../events';

import { Button } from '@ui';

const Container = styled.div`
  ${tw`flex flex-col justify-center pt-12 px-5`}
  ${tw`flex md:flex-row md:items-end`}
  ${tw`lg:justify-center`}
`;

export const CallToAction = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <div tw="flex flex-col md:flex-row">
        <Link to="/">
          <Button
            tw="mt-5 md:mt-0 md:w-60 bg-primary text-white hover:opacity-80"
            primary
            shadow
            id="click-gtag-header-cta-landing-page"
          >
            {formatMessage({ id: 'cta.button1' })}
          </Button>
        </Link>
      </div>
    </Container>
  );
};
