import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { MedicineSvg } from '../styles/icons';

const Container = styled.div`
  ${tw`flex w-full flex-col lg:flex-row md:pt-10 lg:pt-0 lg:mt--10 xl:pt-44 xl:pb-40 xl:mt--40`}
  ${tw`lg:justify-between items-center`}
`;

const Title = styled.h1`
  ${tw`uppercase pb-5`}
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #00b1ff;
`;

const Subtitle = styled.h2`
  ${tw`text-3xl font-bold w-full`}
`;

const Description = styled.div`
  ${tw`pt-6 text-medium w-full`}
  ${tw`xl:text-xl`}
`;

export const Appointments = () => {
  const { formatMessage, locale } = useIntl();

  return (
    <Container>
      <MedicineSvg
        tw="w-full mx-auto mb-10 pt-16 lg:(mb-0 pt-0) md:(max-width[400px] max-height[400px]) lg:(max-width[412px] max-height[412px]) xl:(max-width[512px] max-height[512px])"
        style={{ maxWidth: 512 }}
      />

      <div tw="flex flex-col lg:w-5/12 text-left lg:text-left pr-1">
        {locale === 'pl' ? (
          <div>
            <Subtitle tw="text-center lg:text-left">
              {formatMessage({ id: 'service.onDemand.subtitle1' })}
              <span tw="text-primary">
                {formatMessage({ id: 'service.onDemand.subtitle2' })}
              </span>
            </Subtitle>
            <Description>
              <ul className="dots" tw="pl-8">
                <li tw="pb-5">
                  <span tw="font-semibold">
                    {formatMessage({
                      id: 'service.onDemand.points.point1.title',
                    })}
                  </span>
                  <br />
                  {formatMessage({
                    id: 'service.onDemand.points.point1.description',
                  })}
                </li>
                <li tw="pb-5">
                  <span tw="font-semibold">
                    {formatMessage({
                      id: 'service.onDemand.points.point2.title',
                    })}
                  </span>
                  <br />
                  {formatMessage({
                    id: 'service.onDemand.points.point2.description',
                  })}
                </li>
                <li>
                  <span tw="font-semibold">
                    {formatMessage({
                      id: 'service.onDemand.points.point3.title',
                    })}
                  </span>
                  <br />
                  {formatMessage({
                    id: 'service.onDemand.points.point3.description',
                  })}
                </li>
              </ul>
            </Description>
          </div>
        ) : (
          <div>
            <Title tw="text-center lg:text-left">
              {formatMessage({ id: 'service.onDemand.title' })}
            </Title>
            <Subtitle tw="text-center lg:text-left">
              {formatMessage({ id: 'service.onDemand.subtitle1' })}
              <span tw="text-grey">
                {formatMessage({ id: 'service.onDemand.subtitle2' })}
              </span>
              .
            </Subtitle>
            <Description tw="text-center lg:text-left">
              {formatMessage({ id: 'service.onDemand.description' })}
            </Description>
          </div>
        )}
      </div>
    </Container>
  );
};
