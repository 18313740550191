import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Description, Heading } from '../styles/shared-styles';
import { Apple, Gallery, Google } from '../styles/icons';
import { Button } from '@ui';
import { Footer } from '../components/footer';
import phone from '../images/phone.png';
import { getApiURL } from './navigation-ico';
import { useState } from 'react';

export const Container = styled.div`
  ${tw`flex flex-col container justify-between mx-auto relative z-10 pt-40 px-5`}
`;

const Title = styled.h1`
  ${tw`uppercase pb-5 text-white`}
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

const Input = styled.input`
  ${tw`w-full md:w-80 h-16 mt-3 text-white focus:outline-none`}
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-sizing: border-box;
  border-radius: 32px;
  text-indent: 16px;
`;

const P = styled.p`
  ${tw`text-gray-500 text-xs pb-8 md:text-left`}
`;

const HLine = styled.div`
  ${tw`hidden md:block bg-gray-600`}
  height: 40px;
  width: 1px;
`;

export const SignUpBeta = () => {
  const { formatMessage, locale } = useIntl();
  const [email, setEmail] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [error, setError] = useState<string>();

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const resetForm = () => {
    setEmail('');
  };

  async function handleSignUp(e) {
    e.preventDefault();
    try {
      const { status } = await fetch(getApiURL() + '/beta-test', {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (status === 409) {
        setError(formatMessage({ id: 'betaTest.responseConflict' }));
      }
      if (status === 400) {
        setError(formatMessage({ id: 'betaTest.responseError' }));
      }
      if (status === 201) {
        setSuccessMsg(formatMessage({ id: 'betaTest.responseSuccess' }));
      }
      resetForm();
    } catch (err) {
      setError('Please try again later');
      resetForm();
    }
  }

  return (
    <Container id="sign-up">
      <div tw="flex flex-col justify-between items-center md:items-start md:w-1/2">
        <div tw="flex flex-col md:flex-row md:justify-between">
          <div tw="flex md:w-5/6 lg:w-full flex-col">
            <div tw="flex flex-col justify-center items-center text-center md:items-start md:text-left">
              <Title>{formatMessage({ id: 'signUp.title' })}</Title>
              <Heading tw="text-white text-4xl w-3/4 md:(w-full text-5xl) lg:text-6xl xl:text-7xl">
                {formatMessage({ id: 'signUp.subtitle' })}
                <span tw="text-blue">
                  {formatMessage({ id: 'signUp.subtitle2' })}
                </span>
                {formatMessage({ id: 'signUp.subtitle3' })}
              </Heading>

              {locale === 'pl' ? (
                <Description tw="text-white 2xl:whitespace-nowrap">
                  {formatMessage({ id: 'signUp.description' })}
                  <br />
                  {formatMessage({ id: 'signUp.description2' })}
                </Description>
              ) : (
                <Description tw="text-white 2xl:whitespace-nowrap">
                  {formatMessage({ id: 'signUp.description' })}
                </Description>
              )}
            </div>

            {!successMsg ? (
              <div tw="relative pt-20">
                <p tw="absolute top-16 left-4 text-blood">
                  {error ? error : null}
                </p>
                <form onSubmit={handleSignUp} tw="flex flex-col lg:flex-row ">
                  {!successMsg ? (
                    <Input
                      placeholder={formatMessage({ id: 'signUp.placeholder' })}
                      name="email"
                      type="text"
                      required
                      value={email}
                      onChange={onEmailChange}
                    />
                  ) : null}
                  <Button
                    tw="w-full mt-3 md:w-80 lg:w-40 lg:ml-5 bg-blue hover:opacity-80"
                    type="submit"
                    primary
                    id="click-gtag-signup-landing-page"
                  >
                    {successMsg
                      ? successMsg
                      : formatMessage({ id: 'signUp.button' })}
                  </Button>
                </form>
              </div>
            ) : (
              <div tw="flex flex-col relative lg:flex-row pt-20">
                <Button
                  tw="w-full mt-3 md:w-80 lg:w-48 bg-blue hover:opacity-80"
                  type="submit"
                  primary
                >
                  {successMsg
                    ? successMsg
                    : formatMessage({ id: 'signUp.button' })}
                </Button>
              </div>
            )}
          </div>
          <div
            tw="flex lg:hidden justify-end mt-12 md:mt-28 md:pt-20 md:absolute md:w-full md:right-0 md:top--20 transform md:translate-y--2"
            style={{ maxWidth: 405, maxHeight: 836 }}
          >
            <img src={phone} alt="app phone" tw="mr-1 md:mr-0" />
          </div>
        </div>
      </div>
      <div tw="w-full md:py-24 text-center">
        <P>{formatMessage({ id: 'signUp.available' })}</P>
        <div tw="flex flex-col items-center md:flex-row w-full md:w-4/6">
          <Apple tw="md:pr-5" />
          <HLine />
          <Google tw="my-5 md:mx-7" />
          <HLine />
          <Gallery tw="ml-4 md:pl-5" />
        </div>
      </div>
      <Footer />
    </Container>
  );
};
