import tw, { styled } from 'twin.macro';
import { FAQ_ITEMS } from '../content';
import { Heading } from '../styles/shared-styles';
import { FAQItem } from './faq-item';

const Container = styled.div`
  ${tw`flex flex-col py-32 px-5 pt-24 mb--80 relative`}
`;

export const FAQ = () => {
  return (
    <Container id="faq">
      <Heading tw="text-center pb-10">FAQ</Heading>
      {FAQ_ITEMS.map((item, i) => (
        <FAQItem key={i} answer={item.answer} question={item.question} />
      ))}
    </Container>
  );
};
