import tw, { styled } from 'twin.macro';
import { Description } from '../styles/shared-styles';
import { useIntl, Link } from 'gatsby-plugin-react-intl';
import { Button } from '@ui';

const Container = styled.div`
  ${tw`flex flex-col md:grid md:grid-cols-3 py-20 pb-12 pt-28 md:pt-96 md:pb-16 md:px-5`}
`;

const BigNumber = styled.p`
  ${tw`hidden md:flex text-6xl absolute text-light opacity-25 top--5 left--3`}
`;

const BigLetter = styled.p`
  ${tw`hidden md:flex absolute text-light`}
  font-size: 144px;
`;

const Item = styled.div`
  ${tw`flex flex-col pt-10 md:py-7 items-center`}
`;

const Title = styled.h2`
  ${tw`text-4xl font-bold`}
`;

const Subtitle = styled.h3`
  ${tw`text-xl font-bold`}
`;

const P = styled.p`
  ${tw`font-bold`}
`;

const BtnCTA = styled.button`
  width: 256px;
  height: 64px;

  background: rgba(0, 177, 255, 0.1);
  border-radius: 20px;
`;

export const Blockchain = () => {
  const { formatMessage, locale } = useIntl();

  return (
    <Container id="blockchain">
      <Item tw="md:items-center">
        {locale === 'pl' ? (
          <Title>
            0 <span tw="text-grey">PLN</span>
          </Title>
        ) : (
          <Title>
            1 <span tw="text-grey">%</span>
          </Title>
        )}
        <Subtitle>{formatMessage({ id: 'blockchain.categories' })}</Subtitle>
      </Item>
      <Item tw="md:place-content-center md:items-center md:pl-5">
        <Title tw="md:relative md:left--4">
          -50<span tw="text-grey">%</span>
          <BigNumber>50%</BigNumber>
        </Title>
        <Subtitle>{formatMessage({ id: 'blockchain.priceOff' })}</Subtitle>
      </Item>
      <Item tw="md:pl-5 md:items-center xl:pl-10">
        <Title tw="relative">
          3<span tw="text-grey">x</span>
          <BigLetter tw="text-6xl left--12 top--4 opacity-30">x</BigLetter>
          <BigLetter
            style={{ transform: 'translateX(2px) translateY(0.5px)' }}
            tw="text-8xl left--5 top--7 opacity-20"
          >
            x
          </BigLetter>
          <BigLetter
            style={{ transform: 'translateX(14px) translateY(1.5px)' }}
            tw="left-4 top-2 opacity-10"
          >
            x
          </BigLetter>
        </Title>
        <Subtitle>{formatMessage({ id: 'blockchain.appointment' })}</Subtitle>
      </Item>

      <Item tw="text-justify col-span-full md:(col-span-2)">
        <Description tw="font-medium text-lg px-3 md:(text-2xl mt-0 mr-6)">
          <span tw="text-primary">
            {formatMessage({ id: 'blockchain.description' })}
          </span>
          {formatMessage({ id: 'blockchain.description1' })}
          <br />
          {formatMessage({ id: 'blockchain.description2' })}
          <span tw="text-blood">
            {formatMessage({ id: 'blockchain.description3' })}
          </span>
          {formatMessage({ id: 'blockchain.description4' })}
          <span tw="text-grey">
            {formatMessage({ id: 'blockchain.description5' })}
          </span>
        </Description>
      </Item>
      <Item tw="w-full order-6 place-self-center md:(ml-auto mt-7) lg:(ml-0)">
        <Link to="/">
          <Button
            tw="bg-blood w-60 md:w-60 text-white rounded-full"
            primary
            id="click-gtag-blockchain-buy-bida-landing-page"
          >
            {formatMessage({ id: 'cta.button3' })}
          </Button>
        </Link>
      </Item>
    </Container>
  );
};
