import { HOW_ITEMS } from '../content';
import tw, { css, styled, theme } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Description, Heading, Subtitle, Title } from '../styles/shared-styles';
import { Line1, Line2, Twister } from '../styles/icons';

const Item = styled.div(({ top }) => [
  tw`flex flex-col items-center flex-shrink w-full relative text-center py-12 z-20 md:w-56 xl:w-72 2xl:w-96`,
  css`
    @media (min-width: 768px) {
      top: ${top ? top - 10 + 'px' : null};
    }
    @media (min-width: 1536px) {
      top: ${top ? top + 40 + 'px' : null};
    }
  `,
]);

const HLine1 = styled.div`
  ${tw`hidden md:flex md:w-4/6 lg:w-3/4 bg-gray-100 absolute top-56 transform translate-y-1`}
  min-height: 1px;
`;

const HLine2 = styled.div`
  ${tw`hidden md:flex md:w-4/6 lg:w-3/4 bg-gray-100 absolute top-40 right-0 transform translate-y-1`}
  min-height: 1px;
`;

export const HowItWorks = () => {
  const { formatMessage, locale } = useIntl();

  const t = (key: string) => formatMessage({ id: key });

  return (
    <div tw="flex flex-col pt-32 relative lg:px-5" id="how-it-works">
      <Heading tw="font-bold text-center lg:w-1/2 self-center">
        {formatMessage({ id: 'howItWorks.h2' })}
        <span tw="-ml-1 text-grey">?</span>
      </Heading>
      <Description tw="text-medium text-center lg:w-1/2 self-center px-5">
        {formatMessage({ id: 'howItWorks.description' })}
      </Description>

      <div tw="md:relative">
        <HLine2 />
        {locale === 'pl' ? (
          <Title tw="pt-20 md:relative md:top-24 text-center md:text-right">
            {formatMessage({ id: 'howItWorks.userH1' })}
            <span tw="text-primary">
              {formatMessage({ id: 'howItWorks.userH12' })}
            </span>
            :
          </Title>
        ) : (
          <Title tw="pt-20 md:relative md:top-24 text-center md:text-right">
            {formatMessage({ id: 'howItWorks.userH1' })}
            <span tw="text-primary">
              {formatMessage({ id: 'howItWorks.userH12' })}
            </span>
            ...
          </Title>
        )}
      </div>

      <div tw="w-full flex relative flex-row md:justify-between flex-wrap md:flex-nowrap">
        <Line1 tw="hidden absolute top-32 right-24 md:flex md:w-3/4 lg:w-5/6 xl:right-48 2xl:w-full 2xl:right-12 2xl:top-48" />
        {HOW_ITEMS.slice(0, 3).map((item, i) => (
          <Item key={i} top={item.top}>
            <div>
              <item.Icon
                alt=""
                tw="w-11/12 mx-auto my--10 xl:my--6 2xl:my--3"
              />
            </div>
            <div>
              <Subtitle tw="flex justify-center pt-12 md:pt-4 text-xl">
                {t(item.title)}
              </Subtitle>
              <Description tw="text-medium md:text-sm lg:text-base pt-3">
                {t(item.description)}
              </Description>
            </div>
          </Item>
        ))}
      </div>
      <div tw="md:relative md:top-24">
        <HLine1 />
        {locale === 'pl' ? (
          <Title tw="pt-20 md:pt-60 text-center md:text-left">
            {formatMessage({ id: 'howItWorks.businessH1' })}
            <span tw="text-blood">
              {formatMessage({ id: 'howItWorks.businessH12' })}
            </span>
            <span>:</span>
          </Title>
        ) : (
          <Title tw="pt-20 md:pt-60 text-center md:text-left">
            ...{formatMessage({ id: 'howItWorks.businessH1' })}
            <span tw="text-blood">
              {formatMessage({ id: 'howItWorks.businessH12' })}
            </span>
            .
          </Title>
        )}
      </div>

      <Twister tw="hidden h-1/3 absolute xl:(flex top-1/2 right-0 translate-y-10 w-2/12) 2xl:(top-1/2 w-3/12 h-1/3 transform translate-y-14)" />
      <div tw="w-full flex relative flex-row flex-wrap md:flex-nowrap justify-between">
        <Line2 tw="hidden absolute top-32 right-20 md:flex md:w-3/4 lg:right-20 lg:top-36 lg:w-5/6 xl:right-44 2xl:w-full 2xl:top-52 2xl:right-20" />
        {HOW_ITEMS.slice(3).map((item, i) => (
          <Item key={i} top={item.top}>
            <div tw="mb--6">
              <item.Icon
                alt=""
                tw="w-11/12 mx-auto my--10 xl:my--6 2xl:my--3"
              />
            </div>
            <div>
              <Subtitle tw="pt-16 mt-2 md:pt-10 text-xl">
                {t(item.title)}
              </Subtitle>
              <Description tw="text-medium md:text-sm lg:text-base pt-3">
                {t(item.description)}
              </Description>
            </div>
          </Item>
        ))}
      </div>
    </div>
  );
};
