import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Career } from '../styles/icons';

const Container = styled.div`
  ${tw`flex flex-col w-full`}
  ${tw`lg:flex-row lg:items-center lg:justify-between`}
`;

const Title = styled.h1`
  ${tw`uppercase pb-5 text-blood`}
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

const Subtitle = styled.h2`
  ${tw`text-3xl font-bold`}
  ${tw`w-full lg:w-5/6 xl:w-1/2`}
`;

const Description = styled.p`
  ${tw`pt-6 text-medium`}
  ${tw`xl:text-xl`}
  max-width: 600px;
`;

export const FinancialBootstrap = () => {
  const { formatMessage, locale } = useIntl();

  return (
    <Container
      tw="flex flex-col-reverse justify-center px-5 text-center lg:text-left w-full mt-10 lg:pt-40 pb-10"
      id="financial"
    >
      <div tw="flex flex-col justify-center items-center lg:items-start lg:w-7/12 2xl:pl-5">
        <Title>{formatMessage({ id: 'service.financial.title' })}</Title>
        {locale === 'pl' ? (
          <Subtitle>
            {formatMessage({ id: 'service.financial.subtitle' })}
            <span tw="text-grey">
              {formatMessage({ id: 'service.financial.subtitle2' })}
            </span>{' '}
            {formatMessage({ id: 'service.financial.subtitle3' })}
            <span tw="text-grey">
              {formatMessage({ id: 'service.financial.subtitle4' })}
            </span>
            .
          </Subtitle>
        ) : (
          <Subtitle>
            {formatMessage({ id: 'service.financial.subtitle' })}
            <span tw="text-grey">
              {formatMessage({ id: 'service.financial.subtitle2' })}
            </span>{' '}
            <span>{formatMessage({ id: 'service.financial.subtitle3' })}</span>
          </Subtitle>
        )}

        <Description>
          {formatMessage({ id: 'service.financial.description' })}
        </Description>
      </div>
      <Career tw="w-full mx-auto mb-10 lg:mb-0 max-width[400px] lg:(max-width[312px]) xl:(max-width[512px])" />
    </Container>
  );
};
