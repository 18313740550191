import { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { ChevronDownIcon } from '@heroicons/react/solid';

const Item = styled.div`
  ${tw`flex flex-col pb-10`}
`;

const Question = styled.p`
  ${tw`text-base font-semibold py-3 flex-shrink`}
`;

const Answer = styled.p`
  ${tw`text-sm text-medium py-3 pb-5`}
`;

export const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  const { formatMessage } = useIntl();
  const t = (key: string) => formatMessage({ id: key });

  return (
    <Item>
      <div
        tw="flex flex-row w-full justify-between items-center border-b border-gray-100"
        onClick={() => toggleFAQ()}
      >
        <Question>{t(question)}</Question>
        {isOpen ? (
          <ChevronUpIcon tw="w-5 h-5 ml-2" />
        ) : (
          <ChevronDownIcon tw="w-5 h-5 ml-2" />
        )}
      </div>

      {isOpen ? <Answer>{t(answer)}</Answer> : null}
    </Item>
  );
};
