import { useIntl } from 'gatsby-plugin-react-intl';
import tw, { styled, css } from 'twin.macro';
import { Booksy, DocPlanner, FBIconBlue, IGorig } from '../styles/icons';
import { Description } from '../styles/shared-styles';

import UserSvg from '../images/early-adopters/user.svg';

import Img from 'gatsby-image';

export const ReviewItem = ({
  avatar,
  name,
  jobtitle,
  description1,
  description2,
  linkb,
  linkg,
  linkdoc,
  linkfb,
  linkig,
}) => {
  const { formatMessage, locale } = useIntl();

  const t = (key: string) => formatMessage({ id: key });

  return (
    <div tw="md:(p-4)">
      <div
        tw="grid grid-cols-1 gap-5 gap-y-10 text-center place-items[center center] xl:place-items[center center]"
        css={css`
          @media (min-width: 1300px) {
            ${tw`lg:(grid-cols-2 text-left)`}
          }
        `}
      >
        {!avatar ? (
          <img
            src={UserSvg}
            alt={`${name} ${jobtitle}`}
            style={{ width: 262, height: 242 }}
          />
        ) : (
          <Img
            alt={`${name} ${jobtitle}`}
            tw="rounded-3xl"
            style={{ width: 250, height: 242, background: 'black' }}
            fluid={{
              ...avatar,
            }}
          />
        )}
        <div tw="flex flex-col flex-grow">
          <h2 tw="font-medium md:text-xl text-gray-900">{name}</h2>
          <h3 tw="text-gray-500 mb-3">{t(jobtitle)}</h3>
          <p tw="mb-4">
            {t(description1)} <br />
            {description2}
          </p>
          <div
            tw="inline-flex justify-center"
            css={css`
              @media (min-width: 1300px) {
                ${tw`lg:(justify-start)`}
              }
            `}
          >
            {linkfb && (
              <a
                tw="text-gray-500 mr-2"
                href={linkfb}
                target="_blank"
                rel="noreferrer"
                aria-label="link to early adopter facebook"
              >
                <FBIconBlue />
              </a>
            )}
            {linkdoc && (
              <a
                tw="mx-2 text-gray-500"
                href={linkdoc}
                target="_blank"
                rel="noreferrer"
                aria-label="link to early adopter docplanner"
              >
                <DocPlanner />
              </a>
            )}

            {linkb && (
              <a
                tw="mx-2 mt-1 text-gray-500"
                href={linkb}
                target="_blank"
                rel="noreferrer"
                aria-label="link to early adopter booksy"
              >
                <Booksy style={{ width: 48 }} />
              </a>
            )}

            {linkig && (
              <a
                tw="text-gray-500 mx-2"
                href={linkig}
                target="_blank"
                rel="noreferrer"
                aria-label="link to early adopter instagram"
              >
                <IGorig />
              </a>
            )}
            {linkg && (
              <a
                tw="ml-2 text-gray-500"
                href={linkg}
                target="_blank"
                rel="noreferrer"
                aria-label="link to early adopter website"
              >
                <p tw="text-gray-500 font-semibold">www</p>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
