import { useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';

import { HeroHeader } from '@ui';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Layout from '../components/Layout';
import { Navigation } from '../components/Navigation';
import { CallToAction } from '../components/call-to-action';
import { HowItWorks } from '../components/how-it-works';
import { Blockchain } from '../components/blockchain';
import { EarlyAdopters } from '../components/early-adopters';
import { Appointments } from '../components/appointments';
import { TransparentMarketplace } from '../components/transparent-marketplace';
import { FinancialBootstrap } from '../components/financial-bootstrap';
import { FAQ } from '../components/faq';
import { SignUpBeta } from '../components/sign-up-beta';
import { SEO } from '../components/Seo';
import { Vector } from '../styles/icons';
import { JOBS } from '../content';
import { ArrowCircleDownIcon } from '@heroicons/react/outline';
import { graphql } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import phone from '../images/phone.png';
import { SocialBar } from '../components/social-bar';

gsap.registerPlugin(ScrollTrigger);

const H1 = styled.h1`
  ${tw`font-bold text-center text-black`}
  ${tw`lg:text-6xl 2xl:text-7xl`}
  font-size: 2.3rem;
  line-height: 2.3rem;
  max-width: 1187px;

  @media (max-height: 567px) {
    padding-top: 110px;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 2.75rem;
    line-height: 2.75rem;
  }
`;

const P = styled.p`
  ${tw`pt-6 text-lg lg:text-xl text-black mx-auto px-5`}
  ${tw`text-center`}
  max-width: 702px;
`;

const Main = styled.main`
  ${tw`flex flex-col relative justify-center w-full h-auto mt-32 md:mt-10 lg:mt-32 bg-white z-20`}
`;

const Rectangle = styled.div`
  ${tw`rounded-bl-3xl rounded-br-3xl z-20 absolute top--1 md:top-0 2xl:top--1 bg-white`}
  min-width: 100%;
  min-height: 5vh;
`;

const PhoneMargin = styled.div`
  ${tw`hidden lg:flex justify-end absolute right-0 top--44 w-1/2 xl:top--40 xl:right-0 2xl:w-1/2 2xl:top--48 2xl:ml-auto z-20`}

  @media (min-width: 1920px) {
    right: 10%;
  }

  @media (min-width: 1536px) {
    right: 8%;
  }
`;

const UL = styled.ul`
  margin-top: 0;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  height: 80px;

  > li {
    height: 60px;
    margin-bottom: 20px;
  }

  @-webkit-keyframes change {
    0% {
      transform: translate3d(0, 0px, 0);
    }
    10% {
      transform: translate3d(0, 0px, 0);
    }

    25% {
      transform: translate3d(0, -80px, 0);
    }

    40% {
      transform: translate3d(0, -160px, 0);
    }

    55% {
      transform: translate3d(0, -240px, 0);
    }

    70% {
      transform: translate3d(0, -320px, 0);
    }

    85% {
      transform: translate3d(0, -400px, 0);
    }

    100% {
      transform: translate3d(0, -480px, 0);
    }
  }

  @media (max-width: 767px) {
    height: 40px;

    > li {
      height: 30px;
      margin-bottom: 10px;
    }

    @-webkit-keyframes change {
      0% {
        transform: translate3d(0, 0px, 0);
      }
      10% {
        transform: translate3d(0, 0px, 0);
      }

      25% {
        transform: translate3d(0, -40px, 0);
      }

      40% {
        transform: translate3d(0, -80px, 0);
      }

      55% {
        transform: translate3d(0, -120px, 0);
      }

      70% {
        transform: translate3d(0, -160px, 0);
      }

      85% {
        transform: translate3d(0, -200px, 0);
      }

      100% {
        transform: translate3d(0, -240px, 0);
      }
    }
  }

  @media (max-width: 1023px) and (min-width: 768px) {
    height: 60px;

    > li {
      height: 40px;
      margin-bottom: 20px;
    }

    @-webkit-keyframes change {
      0% {
        transform: translate3d(0, 0px, 0);
      }
      10% {
        transform: translate3d(0, 0px, 0);
      }

      25% {
        transform: translate3d(0, -60px, 0);
      }

      40% {
        transform: translate3d(0, -120px, 0);
      }

      55% {
        transform: translate3d(0, -180px, 0);
      }

      70% {
        transform: translate3d(0, -240px, 0);
      }

      85% {
        transform: translate3d(0, -300px, 0);
      }

      100% {
        transform: translate3d(0, -360px, 0);
      }
    }
  }
`;

export const LI = styled.li`
  ${tw`text-blood text-center sm:(text-left) md:(text-left)`}
`;

export const LIen = styled.li`
  ${tw`text-blood text-center`}
`;

export const JobAnimation = ({ jobs }) => {
  const { formatMessage, locale } = useIntl();

  const t = (key: string) => formatMessage({ id: key });

  return (
    <div
      tw="overflow-hidden ml-3 inline-block"
      style={{ marginTop: locale === 'pl' ? 0 : 12 }}
    >
      <UL>
        {jobs.map((job) =>
          locale === 'pl' ? (
            <LI key={job.name}>
              {t(job.name)}
              <span tw="text-black">.</span>
            </LI>
          ) : (
            <LIen tw="text-center" key={job.name}>
              {t(job.name)}
              <span tw="text-black">.</span>
            </LIen>
          )
        )}
      </UL>
    </div>
  );
};

export function Business({ location, data }) {
  const { formatMessage, locale } = useIntl();

  const t = (key: string) => formatMessage({ id: key });

  useEffect(() => {
    gsap.to('#header', {
      yPercent: 60,
      opacity: -1,
      scrollTrigger: {
        trigger: '#header',
        start: 'center 30%',
        end: '1200 0',
        anticipatePin: 10,
        scrub: 0.5,
      },
    });
  }, []);

  return (
    <Layout tw="font-body flex flex-col relative">
      <SEO description={t('seo.description')} location={location} />
      <Navigation />

      <div id="header">
        <HeroHeader tw="min-h-screen flex justify-center pt-24 sm:pt-0">
          <div tw="flex relative justify-center flex-col w-full">
            <H1 tw="flex flex-row flex-wrap justify-center mx-auto">
              {t('hero.main1')}
              <JobAnimation jobs={JOBS} />
            </H1>
            {locale === 'pl' ? (
              <P>
                {t('hero.sub')}
                <br />
                {t('hero.sub2')}
              </P>
            ) : (
              <P>{t('hero.sub')}</P>
            )}
            <CallToAction />
            <p
              tw="text-xs font-medium text-center mt-8 md:mt-20 text-primary cursor-pointer"
              className="arrow-opacity"
              onClick={() => {
                scrollTo('#appointments');
              }}
            >
              {t('cta.arrow')}
            </p>
            <ArrowCircleDownIcon
              className="arrow-opacity"
              tw="w-16 h-16 relative top-2 mx-auto text-primary cursor-pointer"
              onClick={() => scrollTo('#appointments')}
            />
          </div>
        </HeroHeader>
        <div tw="hidden absolute bottom-8 right-0 left-0 md:flex">
          <SocialBar />
        </div>
      </div>

      <Main id="main">
        {locale === 'pl' ? (
          <Vector tw="hidden md:flex absolute w-full top-96 mt-96 transform md:translate-y-60 lg:(translate-y-12 mt-60) xl:(translate-y-96 mt-52 pb-4) 2xl:(translate-y-96 pb-0) z-10" />
        ) : (
          <Vector tw="hidden md:flex absolute w-full top-96 mt-64 transform md:translate-y-64 lg:(translate-y-24 mt-52 pb-2) xl:(translate-y-96 mt-52) 2xl:(translate-y-96 mt-52 pb-0) z-10" />
        )}
        <div tw="container flex flex-col relative justify-between container mx-auto">
          <section tw="pt-32 md:pt-20 lg:pt-32" id="appointments">
            <Appointments />
            <div tw="relative flex flex-col justify-center">
              <H1 tw="font-bold text-black pt-32 md:pt-52 pb-20 md:w-3/4 self-center z-20">
                {t('service.h21')}
                <span tw="text-grey">{t('service.h22')}</span>
                <span>{t('service.h23')}</span>
              </H1>
            </div>

            <TransparentMarketplace />
            <FinancialBootstrap />
          </section>

          <HowItWorks />
          <Blockchain />
          <EarlyAdopters data={data?.allFile?.edges} />
          <FAQ />
        </div>
        <div tw="sticky bottom-8 mx-auto md:flex mt-80 z-50">
          <SocialBar />
        </div>

        <div tw="w-full bg-dark relative">
          <Rectangle />

          <div tw="relative px-3">
            <SignUpBeta tw="relative z-40" />
            <PhoneMargin>
              <img
                src={phone}
                alt="app phone"
                tw="max-width[580px] max-height[924px] xl:(max-width[645px] max-height[1076px])"
              />
            </PhoneMargin>
          </div>
        </div>
      </Main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "early-adopters" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 420, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default Business;
