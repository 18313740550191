import tw, { styled } from 'twin.macro';
import { Heading, Subtitle } from '../styles/shared-styles';
import { useIntl } from 'gatsby-plugin-react-intl';
import { EARLY_ADOPTERS } from '../content';
import { ReviewItem } from './early-adopter-item';

const Container = styled.div`
  ${tw`flex flex-col py-12 md:py-28`}
`;

const BigDot = styled.li`
  ${tw`absolute`}
  line-height: 0;
`;

export interface EarlyAdoptersProps {
  data: any[];
}

export const EarlyAdopters = ({ data }: EarlyAdoptersProps) => {
  const { formatMessage, locale } = useIntl();

  const withImg = EARLY_ADOPTERS.map((adopter) => ({
    ...adopter,
    avatar: data.find((file) => file?.node?.base === adopter.avatar)?.node
      ?.childImageSharp?.fluid,
  }));

  return (
    <Container id="early-adopters" tw="my-20">
      <div tw="">
        <div tw="flex flex-col text-center w-full mb-20">
          {locale === 'pl' ? (
            <Heading tw="text-center md:w-4/6 md:self-center md:pb-12">
              {formatMessage({ id: 'earlyAdopters.title' })}
              <span tw="text-grey">
                {formatMessage({ id: 'earlyAdopters.title2' })}
              </span>
              .
            </Heading>
          ) : (
            <Heading tw="text-center md:w-4/6 md:self-center md:pb-12">
              <span tw="text-grey">
                {formatMessage({ id: 'earlyAdopters.title' })}
              </span>

              {formatMessage({ id: 'earlyAdopters.title2' })}
              <span tw="text-grey">.</span>
            </Heading>
          )}
        </div>
        <div>
          <div tw="grid grid-cols-1 gap-y-10 gap-x-5 lg:(grid-cols-2 gap-12)">
            {withImg.map((item, i) => (
              <ReviewItem
                key={i}
                avatar={item.avatar}
                name={item.name}
                jobtitle={item.jobTitle}
                description1={item.description1}
                description2={item.description2}
                linkb={item.linkB}
                linkg={item.linkG}
                linkdoc={item.linkDoc}
                linkfb={item.linkFB}
                linkig={item.linkIG}
              />
            ))}
          </div>
          <div tw="mt-16 relative">
            <div tw="text-2xl text-center font-semibold md:text-3xl lg:(pt-16 text-right pr-7) relative">
              <p tw="">
                {formatMessage({ id: 'earlyAdopters.many1' })}
                <span tw="text-grey">
                  {formatMessage({ id: 'earlyAdopters.many2' })}
                </span>
                {formatMessage({ id: 'earlyAdopters.many3' })}...
              </p>
            </div>
            <ul tw="relative text-gray-200">
              <BigDot tw="text-9xl right-8 bottom-9 opacity-60">.</BigDot>
              <BigDot
                tw="text-9xl right-24 bottom-12 opacity-40"
                style={{ fontSize: 200 }}
              >
                .
              </BigDot>
              <BigDot
                style={{ fontSize: 240 }}
                tw="bottom-16 right-28 opacity-30"
              >
                .
              </BigDot>
              <BigDot
                style={{ fontSize: 280 }}
                tw="bottom-16 right-48 opacity-30"
              >
                .
              </BigDot>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  );
};
