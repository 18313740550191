import { HealthSvg } from '../styles/icons';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';

const Container = styled.div`
  ${tw`flex flex-col w-full`}
  ${tw`lg:flex-row lg:items-center lg:justify-between`}
`;

const Title = styled.h1`
  ${tw`uppercase pb-5 text-blood text-center lg:text-left`}
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

const Subtitle = styled.h2`
  ${tw`text-3xl font-bold`}
  ${tw`w-full lg:w-2/3 xl:w-1/3`}
`;

const Description = styled.p`
  ${tw`pt-6 text-medium`}
  ${tw`xl:text-xl`}
  max-width: 600px;
`;

export const TransparentMarketplace = () => {
  const { formatMessage, locale } = useIntl();

  return (
    <Container
      tw="flex flex-col-reverse justify-center px-5 text-center lg:text-left w-full mt-20 lg:mt-0 lg:pt-40 pb-20"
      id="marketplace"
    >
      <div tw="flex flex-col justify-center items-center lg:items-start lg:w-6/12 2xl:pl-5">
        <Title>{formatMessage({ id: 'service.marketplace.title' })}</Title>
        <Subtitle tw="lg:whitespace-nowrap">
          {formatMessage({ id: 'service.marketplace.subtitle' })}
          <span tw="text-grey">Bidda</span>.
        </Subtitle>

        {locale === 'pl' ? (
          <Description>
            {formatMessage({ id: 'service.marketplace.description' })}
          </Description>
        ) : (
          <Description>
            {formatMessage({ id: 'service.marketplace.description' })}
            <br />
            {formatMessage({ id: 'service.marketplace.description2' })}
            <span tw="font-semibold">
              {formatMessage({ id: 'service.marketplace.description3' })}
            </span>
            {formatMessage({ id: 'service.marketplace.description4' })}
            <span tw="font-semibold">
              {formatMessage({ id: 'service.marketplace.description5' })}
            </span>
          </Description>
        )}
      </div>
      <HealthSvg tw="w-full mx-auto mb-10 lg:mb-0 max-width[400px] lg:(max-width[412px]) xl:(max-width[512px])" />
    </Container>
  );
};
